import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

import Title from "../../../../commons/Components/Title";
import Button from "../../../../commons/Components/Button";
import Map from "../../../../commons/Components/Map";

const CTA = ({ title, subtitle, buttonText }) => (
  <Wrapper id="about">
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
    <SubtitleWrapper>{subtitle}</SubtitleWrapper>
    <Map></Map>
  </Wrapper>
);

CTA.propTypes = {
  title: string,
  subtitle: string,
  buttonText: string
};

CTA.defaultProps = {
  title: "",
  subtitle: "",
  buttonText: ""
};

export default CTA;

const Wrapper = styled.div`
  max-width: 95vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  & > a {
    margin: 32px 0 80px 0;
  }

  @media (max-width: 480px) {
    & > a {
      margin-bottom: 80px;
    }
  }
`;

const TitleWrapper = styled.div`
  margin-top: 50px;

  @media (max-width: 480px) {
    margin-top: 60px;
    margin-bottom: 20px;
  }
`;

const SubtitleWrapper = styled.div`
  font-size: 20px;

  @media (max-width: 480px) {
    max-width: 330px;
    margin: 0 auto;
  }
`;
