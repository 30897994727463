import React from "react";
import styled from "styled-components";

const MapContainer = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  margin: 20px auto;
  width: 80%;
  margin-top: 40px;
  & > * {
    width: 100%;
    border: none;
    height: 300px;
  }
`;

const Map = () => {
  const location = {
    latitude: 46.9767996,
    longitude: -1.5771494,
    address: "17 rue de la guillauderie, 44118 la Chevroliere"
  };

  const zoomLevel = 15; // Adjust the zoom level as needed

  return (
    <>
      <MapContainer>
        <iframe 
          loading="lazy" 
          src="https://maps.google.com/maps?q=17%20rue%20de%20la%20guillauderie%2C%2044118%20la%20Chevroliere&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" 
          title="nordpro"
          aria-label="nordpro"
        ></iframe>
      </MapContainer>
    </>
  );
};

export default Map;
