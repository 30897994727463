import React, { useState } from "react";
import styled from "styled-components";
import Button from "../Button";
import { translate as t } from "../../../helpers/i18n";
import emailjs from "emailjs-com";
import { message as antdMessage } from "antd";

const FormContainer = styled.div`
  background-color: #19222c;
  padding: 20px;
  border-radius: 8px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  margin: 20px;
  padding: 30px;
  margin-top: 60px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: #fff;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 20px;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
`;

const TextArea = styled.textarea`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 100%;
  resize: none;
  margin-bottom: 30px;
`;

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      antdMessage.error("Please enter a valid email address");
      return;
    }
    try {
      await emailjs.send(
        "service_hv84xpm",
        "template_ke5hsmr",
        {
          name,
          email,
          message,
          phone
        },
        "jKsN-xzMXvQQncdf2"
      );
      antdMessage.success("Email sent successfully");
    } catch (err) {
      console.error(err);
      antdMessage.error(err.text);
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={sendEmail}>
        <Label htmlFor="name">{t("Nom")}</Label>
        <Input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <Label htmlFor="number">{t("Numéro de téléphone")}</Label>
        <Input
          type="number"
          id="number"
          name="number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
        />

        <Label htmlFor="message">{t("Message")}</Label>
        <TextArea
          id="message"
          name="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="4"
          required
        />

        <Button underline type="submit">
          <Style>{t("Envoyer")}</Style>
        </Button>
      </Form>
    </FormContainer>
  );
};

const Style = styled.div`
  font-size: 20px;
  color: #97e073;
  &:after {
    content: none;
  }
`;

export default ContactForm;
