import React from 'react'
import { string, bool } from 'prop-types'
import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  position: relative;
  padding: ${props => (props.checked ? '0 0 0 30px' : '8px 15px')};
  font-weight: bold;
  font-size: ${(props) => {
    if (props.small) return '12px'
    if (props.large) return '16px'
    return '14px'
  }};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => {
    if (props.disabled) return '#9b9b9b4d'
    if (props.checked) return `${props.theme.palette.main}66`
    // eslint-disable-next-line max-len
    if (props.underline || props.invert || props.isCheckable) return 'transparent'
    if (props.redColor) return '#d908084d'
    return `${props.theme.palette.main}4d`
  }};
  color: ${(props) => {
    if (props.disabled) return '#9b9b9b'
    if (props.checked) return props.theme.palette.main
    if (props.isCheckable) return '#444444'
    if (props.redColor) return '#d90808'
    return props.theme.palette.main
  }};
  border: 1px solid ${(props) => {
    if (props.checked) return 'transparent'
    if (props.isCheckable) return '#4444444d'
    if (props.invert) return `${props.theme.palette.main}66`
    return 'transparent'
  }};
  border-radius: 5px;
  :focus {
    outline: none;
  }
  :hover {
    border-color: transparent;
    background-color: ${(props) => {
      if (props.disabled) return '#9b9b9b4d'
      if (props.underline) return 'transparent'
      if (props.redColor) return '#d9080866'
      return `${props.theme.palette.main}66`
    }};
  }

  ${props => props.checked
    && css`
      :before {
        content: '✓';
        position: absolute;
        font-size: 20px;
        top: 50%;
        transform: translate(calc(-100% - 5px), -50%);
      }
    `}
  ${props => props.underline
    && css`
      :after {
        position: absolute;
        content: '';
        background-color: ${props.disabled
    ? ' #9b9b9b4d'
    : `${props.theme.palette.main}4d`};
        height: 2px;
        width: calc(100% - 30px);
        left: 15px;
        top: calc(100% - 8px);
      }
      :hover:after {
        background-color: ${props.disabled
    ? ' #9b9b9b4d'
    : `${props.theme.palette.main}4d`};
      }
    `}
`

const Button = ({
  className,
  underline,
  invert,
  checked,
  disabled,
  isCheckable,
  small,
  large,
  redColor,
  ...props
}) => (
  <StyledButton
    type="button"
    {...props}
    className={className}
    disabled={disabled}
    checked={checked}
    isCheckable={isCheckable}
    small={small}
    large={large}
    underline={underline}
    invert={invert}
    redColor={redColor}
  />
)

Button.propTypes = {
  className: string,
  underline: bool,
  invert: bool,
  checked: bool,
  disabled: bool,
  isCheckable: bool,
  redColor: bool,
  small: bool,
  large: bool,
}

Button.defaultProps = {
  className: '',
  underline: false,
  invert: false,
  checked: false,
  disabled: false,
  isCheckable: false,
  redColor: false,
  small: false,
  large: false,
}

export default Button
