import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  Wrapper,
  LeftContainer,
  LogoContainer,
  Arrow,
  RightContainer,
  LanguageDropDown,
  Language,
  LanguageButton,
  PhoneSvg,
  ScrolledHeaderPhone
} from "./styles";

import { translate as t, setLocale } from "../../helpers/i18n";
import useBlur from "../../commons/hooks/useBlur";
import BrandIcon from "../../BrandIcon";
import { ReactComponent as Chevron } from "./svgs/chevron.svg";

import { ReactComponent as Linkedin } from "../../commons/svg/social/linkedin.svg";

import {
  isPathTransparentHeader,
  isPathWhiteHeader,
  locale,
  topPageRedirection
} from "./helpers";

import MobileHeader from "./Mobile";
import { getPathnameWithLocale } from "../../helpers/getRouterBasename";
import useWindowDimensions from "../../commons/hooks/useWindowDimensions";

const StaticHeader = () => {
  const [transparentHeader, setTransparentHeader] = useState(
    window.location.pathname === "/about"
  );

  const [whiteHeader, setWhiteHeader] = useState(
    isPathWhiteHeader(window.location.pathname)
  );
  const [ressources, setRessources] = useState(false);
  const [about, setAbout] = useState(false);
  const [language, setLanguage] = useState(false);
  const { width } = useWindowDimensions();

  const [mobileDropDown, setMobileDropDown] = useState(false);
  const [changeLanguage, setChangeLanguage] = useState(locale);

  const mobileButtonRef = useRef(null);
  const userRef = useRef(null);
  const userButtonRef = useRef(null);
  const mobileUserRef = useRef(null);
  const refButtonLanguage = useRef(null);
  const refLanguage = useRef(null);
  const ressourcesRef = useRef(null);
  const ressourcesButtonRef = useRef(null);
  const aboutRef = useRef(null);
  const aboutButtonRef = useRef(null);

  const handleChange = (input) => {
    setLocale(input);
    window.location.pathname = getPathnameWithLocale(
      window.location.pathname,
      input
    );
  };

  const handleScroll = useCallback(() => {
    if (isPathTransparentHeader(window.location.pathname)) {
      setTransparentHeader(window.scrollY <= 50);
      setWhiteHeader(false);
    }
    if (isPathWhiteHeader(window.location.pathname)) {
      setWhiteHeader(window.scrollY <= 50);
      setTransparentHeader(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (width > 960) {
      setMobileDropDown(false);
      setRessources(false);
      setLanguage(false);
    }
    handleScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, window.location.pathname]);

  useBlur((event) => {
    if (
      language &&
      refButtonLanguage.current &&
      refLanguage.current &&
      !refButtonLanguage.current.contains(event.target) &&
      !refLanguage.current.contains(event.target)
    ) {
      setLanguage(false);
    }
    if (
      mobileDropDown &&
      mobileButtonRef.current &&
      mobileUserRef.current &&
      !mobileButtonRef.current.contains(event.target) &&
      !mobileUserRef.current.contains(event.target)
    ) {
      setMobileDropDown(false);
    }

    if (
      ressources &&
      ressourcesRef.current &&
      ressourcesButtonRef.current &&
      !ressourcesRef.current.contains(event.target) &&
      !ressourcesButtonRef.current.contains(event.target)
    ) {
      setRessources(false);
    }
    if (
      about &&
      aboutRef.current &&
      aboutButtonRef.current &&
      !aboutRef.current.contains(event.target) &&
      !aboutButtonRef.current.contains(event.target)
    ) {
      setAbout(false);
    }
  }, []);

  const scrollToSection = (id, yOffset) => {
    const element = document.getElementById(id);
    const y =
      element?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <Wrapper
      $transparentHeader={transparentHeader}
      $whiteHeader={whiteHeader}
      $mobileDropDown={mobileDropDown}
    >
      {width > 1150 ? (
        <div>
          <LeftContainer>
            <LogoContainer
              onClick={() => {
                const targetDiv = document.getElementById("top");
                targetDiv.scrollIntoView({
                  behavior: "smooth",
                  block: "start"
                });
              }}
            >
              {<BrandIcon />}
            </LogoContainer>
          </LeftContainer>
          <RightContainer>
            <Text
              onClick={() => {
                scrollToSection("skills", -100);
              }}
            >
              <Bold style={{ textAlign: "right" }}>{t("Compétences")}</Bold>
            </Text>
            <Text
              onClick={() => {
                scrollToSection("contact", -50);
              }}
            >
              <Bold>{t("Contactez-nous")}</Bold>
            </Text>
            <Text
              onClick={() => {
                scrollToSection("about", -100);
              }}
            >
              <Bold>{t("Où nous trouver ?")}</Bold>
            </Text>
            <ScrolledHeaderPhone
              href="tel:+33252591498"
              rel="noopener noreferrer"
            >
              <PhoneSvg />
              +33 2 52 59 14 40 
            </ScrolledHeaderPhone>
            <LanguageDropDown>
              <LanguageButton
                ref={refButtonLanguage}
                onClick={() => setLanguage(true)}
                $transparentHeader={transparentHeader}
                $whiteHeader={whiteHeader}
                type="button"
              >
                {changeLanguage === "fr" ? "FR" : "EN"}
                <Arrow
                  $transparentHeader={transparentHeader}
                  $whiteHeader={whiteHeader}
                >
                  <Chevron />
                </Arrow>
              </LanguageButton>
              {language && (
                <Language ref={refLanguage}>
                  <button
                    type="button"
                    onClick={() => {
                      handleChange(changeLanguage === "fr" ? "en" : "fr");
                      setChangeLanguage(changeLanguage === "fr" ? "en" : "fr");
                    }}
                  >
                    {t(changeLanguage === "fr" ? "English" : "Français")}
                  </button>
                </Language>
              )}
            </LanguageDropDown>
            <Logos>
              <a
                href="https://www.linkedin.com/company/moving-on-transport/about/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin />
              </a>
            </Logos>
          </RightContainer>
        </div>
      ) : (
        <MobileHeader />
      )}
    </Wrapper>
  );
};

export default StaticHeader;

const Text = styled.div`
  text-align: left;
  max-width: 215px;
  margin: 0 10px;
  min-width: 120px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 50px;
  }

  @media (max-width: 425px) {
    margin: 0px;
  }
`;

const Bold = styled.p`
  font-weight: 600;
  font-size: 16px;
  text-align: center !important;
`;

const Logos = styled.div`
  margin-top: 5px;
  & > a:not(:last-child) {
    margin-right: 20px;
  }
`;
