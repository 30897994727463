import { setLocale } from './i18n'

const locales = ['fr', 'en']

export const getPathnameWithoutLocale = (pathname) => {
  const localeRegex = /^(\/\w{2,3}\/)/g
  const urlLocale = localeRegex.test(pathname)
    && pathname.match(localeRegex)[0].replace(/\//g, '')

  if (!locales.includes(urlLocale)) return pathname
  return pathname.replace(localeRegex, '/')
}

export const getPathnameWithLocale = (pathname, locale) => {
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname)

  if (
    locale === 'fr'
    && (!pathnameWithoutLocale || pathnameWithoutLocale === '/')
  ) {
    return '/'
  }

  return locale === 'fr'
    ? pathnameWithoutLocale
    : `/${locale}${pathnameWithoutLocale}`
}

const getLocaleForStaticPages = () => {
  const urlLocale = window.location.pathname.split('/')[1]
  const isValidLocale = locales.includes(urlLocale)

  return isValidLocale ? urlLocale : ''
}

export const getRouterBasename = (user) => {
  if (user) {
    const pathnameWithoutLocale = getPathnameWithoutLocale(
      window.location.pathname,
    )
    if (window.location.pathname !== pathnameWithoutLocale) {
      window.location.pathname = pathnameWithoutLocale
    }
    return ''
  }
  const locale = getLocaleForStaticPages()

  setLocale(locale || 'fr', true)
  if (locale === 'fr') {
    window.location.pathname = getPathnameWithoutLocale(
      window.location.pathname,
    )
    return ''
  }

  return `${locale ? '/' : ''}${locale}`
}
