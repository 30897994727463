import React from "react";

import styled from "styled-components";

import Title from "../../../../commons/Components/Title";
import ContactForm from "../../../../commons/Components/ContactForm";

import { translate as t } from "../../../../helpers/i18n";

const BookMeeting = () => (
  <Container id="contact">
    <div>
      <LeftContainer>
        <TitleWrapper>
          <Title>{t("Contactez-nous")}</Title>
        </TitleWrapper>
        <Content>
          <SubTitle>
            {t("Moving on Transport saura vous faire gagner du temps.")}
          </SubTitle>
          <br />
          <SubTitle>
            {t(
              "Pour toute demande concernant nos services, veuillez nous contacter et découvrez comment nous pouvons organiser vos prochains affrètements."
            )}
          </SubTitle>
        </Content>
      </LeftContainer>
      <ContactForm></ContactForm>
    </div>
  </Container>
);

export default BookMeeting;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #97e0734d;
  min-height: 600px;
  & > div {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
    & > div {
      justify-content: center;
    }
  }
`;

const LeftContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 300px;
  }
`;

const SubTitle = styled.p`
  font-size: 20px;
  text-align: start;
  color: #444444;

  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 30px;
  padding-left: 15px;
`;
