import Nav from "./Nav";
import MainContextProvider, {
  Context as MainContext
} from "./commons/Components/MainContext";
import Body from "./Layout/Body";
import Header from "./Layout/Header";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

const App = () => (
  <ThemeProvider theme={theme()}>
    <MainContextProvider>
      <MainContext.Consumer>
        {({ displayHeader }) => displayHeader && <Header />}
      </MainContext.Consumer>
      <Body>
        <Nav />
      </Body>
    </MainContextProvider>
  </ThemeProvider>
);

export default App;
