import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FranckImg from "../png/franck.jpg"; // Import Franck image

import Title from "../../../../commons/Components/Title";
import { translate as t } from "../../../../helpers/i18n";
import { ReactComponent as Linkedin } from "../../../../commons/svg/social/linkedin.svg";

import { ReactComponent as One } from "../svgs/howitworks/1.svg";
import { ReactComponent as Two } from "../svgs/howitworks/2.svg";
import { ReactComponent as Three } from "../svgs/howitworks/3.svg";
import { ReactComponent as For } from "../svgs/howitworks/4.svg";

gsap.registerPlugin(ScrollTrigger);

const Howitworks = () => {
  const franckImageRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;

    const franckImage = franckImageRef.current;
    gsap.fromTo(
      textElement,
      { opacity: 0, x: 100 },
      {
        opacity: 1,
        x: 0,
        duration: 2,
        scrollTrigger: {
          trigger: textElement,
          start: "top 80%",
          end: "top 20%",
          scrub: 0.5
        }
      }
    );

    gsap.fromTo(
      franckImage,
      { opacity: 0, x: -100 },
      {
        opacity: 1,
        x: 0,
        duration: 2, // Adjust the duration for a slower appearance
        scrollTrigger: {
          trigger: franckImage,
          start: "top 80%",
          end: "top 20%",
          scrub: 0.5
        }
      }
    );
  }, []);

  return (
    <Wrapper id="skills">
      <TitleWrapper>
        <Title>{t("Nos compétences et notre savoir-faire")}</Title>
      </TitleWrapper>
      <FranckContainer>
        <FranckImage ref={franckImageRef} src={FranckImg} alt="Franck" />
        <Text ref={textRef}>
          <Bold>{t("Franck CAUSSE, fondateur de l'entreprise")}</Bold>
          {t(
            "Fort de plus de 10 années d'expériences dans le transport routier de marchandises, je mettrai mes connaissances métiers à votre disposition pour vous faire vivre des expériences transport de qualité.  Dans l'attente prochaine de notre première expérience commune, vous avez mes coordonnées. Vous pouvez également me retrouver sur LinkedIn "
          )}
          <br></br>
          <br></br>
          <LinkedinContainer
            href="https://linkedin.com/in/causse-franck"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin />
          </LinkedinContainer>
        </Text>
      </FranckContainer>
      <Container>
        <Images>
          <Svgs>
            <Two />
          </Svgs>
          <Separator />
          <Svgs>
            <One />
          </Svgs>
          <Separator />
          <Svgs>
            <For />
          </Svgs>
          <Separator />
          <Svgs>
            <Three />
          </Svgs>
        </Images>
        <TextSection>
          <Text>
            <Bold>{t("Destinations")}</Bold>
            {t(
              "Zones géographiques desservies : " +
                "l'europe de l'ouest quotidiennement & l'espace schengen à la demande."
            )}
          </Text>
          <Text>
            <Bold>{t("Sécurité de votre marchandise")}</Bold>
            {t(
              "Sécurisation de la marchandise sur des sites " +
                "à haute protection avec des bâches renforcées."
            )}
          </Text>
          <Text>
            <Bold>{t("Solution véhicule")}</Bold>
            {t("Transport de tous types de marchandises en camion tautliner.")}
          </Text>
          <Text>
            <Bold>{t("Livraisons ponctuelles")}</Bold>
            {t("L'engagement de respecter vos impératifs de livraison.")}
          </Text>
        </TextSection>
      </Container>
    </Wrapper>
  );
};

export default Howitworks;

const LinkedinContainer = styled.a``;

const FranckImage = styled.img`
  top: 0;
  max-width: 450px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  opacity: 0; // Initially set to 0 for the GSAP animation
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 50px;

  @media (max-width: 900px) {
    margin: 0;
    margin-bottom: 50px;
  }
`;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 50px;
`;

const FranckContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1300px;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
`;

const Container = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 425px) {
    margin-left: 40px;
  }

  @media (max-width: 375px) {
    margin-left: 20px;
  }
`;

const Svgs = styled.div`
  width: 120px;
  height: 120px;

  @media (max-width: 425px) {
    height: 100px;
    width: 100px;
  }

  @media (max-width: 375px) {
    height: 70px;
    width: 70px;
  }
`;

const Separator = styled.div`
  height: 2px;
  width: 130px;
  margin: 10px 10px;
  background-color: #ebeaec;

  @media (max-width: 768px) {
    height: 100px;
    width: 2px;
  }
`;

const Images = styled.div`
  margin: 100px 50px 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 50px;
  }

  @media (max-width: 425px) {
    margin: 50px 10px 50px 0px;
  }
`;

const TextSection = styled.div`
  margin: 30px 50px 120px 50px;
  display: flex;
  justify-content: center;

  & > div:not(:last-child) {
    margin-right: 50px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 130px 20px 50px 30px;
  }

  @media (max-width: 425px) {
    margin: 50px 0 50px 0;
    margin-bottom: 50px;
  }

  @media (max-width: 375px) {
    & > div:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const Text = styled.div`
  text-align: center;
  max-width: 315px;

  @media (max-width: 768px) {
    margin-right: 50px;
  }

  @media (max-width: 425px) {
    margin: 0px;
  }
`;

const Bold = styled.p`
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 20px;
`;
