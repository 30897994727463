import React, { useContext } from "react";
import { string, oneOfType, arrayOf, node } from "prop-types";
import styled, { css } from "styled-components";

import { Context as MainContext } from "../commons/Components/MainContext";

const Body = ({ className, children }) => {
  const { bodyPadding } = useContext(MainContext);

  return (
    <Container bodyPadding={bodyPadding} className={className}>
      {children}
    </Container>
  );
};

Body.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired
};

Body.defaultProps = {
  className: ""
};

export default Body;

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f4f5f6;
  overflow: auto;
  overflow-x: hidden;
  padding-top: 66px;

  ${(props) =>
    !props.bodyPadding &&
    css`
      padding: 0;
    `}

  @media (max-width: 1020px) {
    padding-top: 0;
  }
`;
