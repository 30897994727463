import styled, { css, keyframes } from "styled-components";
import { handleHeaderClass, handleHeaderClassChevron } from "./helpers";
import { ReactComponent as Phone } from "./svgs/phone.svg";

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  height: 84px;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;

  & > div:first-child {
    justify-content: space-between;
    display: flex;
    width: 100%;
    height: 100%;
    backdrop-filter: saturate(180%) blur(20px);
    background: rgba(251, 251, 253, 0.8);
  }

  ${(props) =>
    handleHeaderClass(
      props.$transparentHeader,
      props.$whiteHeader,
      ScrolledHeader,
      TransparentHeader,
      WhiteHeader
    )}

  ${(props) => !!props.$mobileDropDown && ScrolledHeader}
`;

export const TransparentHeaderPhone = styled.div`
  transition: 0.5s;
  font-weight: 600;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.4) !important;
  padding: 4px 8px;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;

  &:hover {
    background: rgba(255, 255, 255, 0.55) !important;
  }
`;

export const ScrolledHeaderPhone = styled.a`
  transition: 0.5s;
  font-weight: 600;
  color: ${(props) => props.theme.palette.main};
  padding: 4px 8px;
  margin: 0;
  border-radius: 5px;
  margin-right: 20px;
  min-width: 115px;
  text-decoration: none;
  :hover {
    background: ${(props) => `${props.theme.palette.main}66`};
  }
  @media (max-width: 1050px) {
    font-size: 15px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;

  & > *:not(last-child) {
    margin-right: 30px;
  }

  & > a {
    display: flex;
    align-items: center;
  }

  & > button,
  & > a {
    background: none;
    cursor: pointer;
  }

  @media (max-width: 1430px) {
    & ${TransparentHeaderPhone}, & ${ScrolledHeaderPhone} {
      display: none;
    }
  }

  @media (max-width: 960px) {
    & ${TransparentHeaderPhone}, & ${ScrolledHeaderPhone} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
    }
  }
`;

export const RightContainer = styled.div`
  margin-right: 28px;
  display: flex;
  align-items: center;
  background-color: transparent;
  & > button:last-child,
  & > a {
    background: none;
    cursor: pointer;
  }

  & > button {
    font-size: 16px;
  }

  & > button > svg {
    margin: 0 0 2px 5px;
  }
`;

export const LogoContainer = styled.a`
  margin-left: -85px;
  padding-right: 10px;
  /* height: 80px; */
  /* width: auto; */
  transform: scale(0.4);

  @media (max-width: 1200px) {
    margin-left: -85px;
  }
`;

const dropdown = css`
  position: relative;
`;
export const ProductDropDown = styled.div`
  ${dropdown}
`;
export const RessourcesDropDown = styled.div`
  ${dropdown}
`;
export const AboutDropDown = styled.div`
  ${dropdown}

  @media (max-width: 1130px) {
    display: none;
  }
`;
export const LanguageDropDown = styled.div`
  ${dropdown}
`;

export const Language = styled.div`
  background-color: #ffffff;
  border-radius: 5px;
  width: 85px;
  height: 45px;
  position: absolute;
  top: 50px;
  right: 0px;
  animation: ${fade} 0.5s;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    border: none;
    background-color: #ffffff;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
  }

  &:before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  & > button:hover {
    background-color: #ebf0ff;
    transition-duration: 0.4s;
    text-decoration: none;
    border-radius: 10px;
  }
`;

export const hover = css`
  &:hover {
    background-color: #f7f7f7;
  }
`;

export const Text = styled.div`
  text-decoration: none;
  cursor: pointer;
  padding: 25px 0;
`;

export const ModalText = css`
  text-decoration: none;
  cursor: pointer;
  color: #444444;
`;

export const MobileModalText = styled.div`
  text-decoration: none;
  cursor: pointer;
`;

export const HeaderButton = styled.div`
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const LanguageButton = styled.button`
  margin-right: 30px;
  font-size: 16px;
  background: transparent !important;
  border: none;
  outline: none;
  cursor: pointer;

  @media (max-width: 1200px) {
    margin: 0;
  }
`;

export const TransparentHeader = css`
  transition: 0.5s;
  background-color: transparent;
  color: #ffffff;
`;

const solidHeader = css`
  transition: 0.5s;
  /* background-color: #ffffff;
  color: #444444; */
  backdrop-filter: saturate(180%) blur(20px);
  background: rgba(251, 251, 253, 0.8);
`;

export const WhiteHeader = css`
  ${solidHeader}
  background-color: transparent;
`;

export const ScrolledHeader = css`
  ${solidHeader}
  background-color: transparent;

  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
`;

export const Why = styled.div`
  padding: 20px;
  animation: ${fade} 0.5s;
  position: absolute;
  top: 50px;
  left: -165px;
  background-color: #ffffff;
  width: 350px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

  &::before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const resourcesAndAbout = css`
  padding: 20px;
  animation: ${fade} 0.5s;
  position: absolute;
  top: 50px;
  left: -30px;
  background-color: #ffffff;
  min-width: 80px;
  width: auto;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);

  &::before {
    content: "";
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
export const Resources = styled.div`
  ${resourcesAndAbout}
`;
export const About = styled.div`
  ${resourcesAndAbout}
`;

export const Bold = styled.p`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
`;

export const Container = styled.div`
  & > div > a,
  & > div > button {
    display: flex;
    padding: 5px;
    text-decoration: none;
    font-size: 16px;
    border: none;
    background: #ffffff;
    width: stretch;
  }

  & > div > a:hover,
  & > div > button:hover {
    background-color: #ebf0ff;
    transition-duration: 0.4s;
    text-decoration: none;
    border-radius: 10px;
  }

  & > div:not(:last-child) > a,
  & > div > button {
    margin-bottom: 30px;
  }

  & > div > a > div {
    font-size: 14px;
  }

  & > div > a > svg {
    min-width: 76px;
    margin-right: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  padding-right: 20px;
  display: flex;
  align-items: center;
`;

export const BurgerIcon = styled.div`
  position: relative;
  display: block;
  width: 30px;
  height: 2px;
  margin: 7px 0;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  background-color: #444444;

  &:after,
  &:before {
    background-color: #444444;
    content: "";
    position: absolute;
    border-radius: 3px;
    display: block;
    width: 30px;
    height: 2px;
    top: 9px;
    transition: all 0.3s ease-in-out;
  }

  &:before {
    top: -9px;
  }

  ${(props) =>
    !!props.$cross &&
    css`
      background-color: transparent;

      &:before {
        transform: translateY(9px) rotate(135deg);
      }

      &:after {
        transform: translateY(-9px) rotate(-135deg);
      }
    `}
`;

export const MobileWrapper = styled.div`
  padding: 0 30px 0 30px;

  @media (max-width: 425px) {
    padding: 0 5px 0 20px;
  }
`;

export const MobileContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  max-width: 250px;
  width: 100%;
  position: absolute;
  height: auto;
  overflow: hidden;
  overscroll-behavior: contain;
  top: 90px;
  right: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  animation: ${fade} 0.5s;

  & > *:nth-last-child(n + 3) {
    padding: 25px 0 25px 0;
    border-bottom: 1px solid #f7f7f7;
  }
  & > *:first-child {
    padding-bottom: 0;
  }
  & > * {
    padding-top: 0;
  }
  padding-top: 0;

  /* @media (max-width: 425px) {
    padding: 20px 50px;
    margin-bottom: 100px;
    width: 100%;
    top: 80px;
    left: 0;
    border-radius: 0;
    box-shadow: none;
  } */

  @media (max-width: 375px) {
    max-width: 275px;
  }

  @media (max-width: 320px) {
    padding: 30px;
    max-width: 240px;

    &::before {
      right: 6.3%;
    }
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;

  & > div:not(:last-child) {
    margin-bottom: 20px;
  }

  & > div:last-child {
    padding-bottom: 25px;
  }
`;

export const MobileRubric = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;

  & > a {
    height: 30px;
    background-color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    animation: ${fade} 1s;
    margin-top: 10px;
    margin-left: 20px;
  }
`;

export const MobileLanguage = styled.div`
  & > button {
    height: 30px;
    background-color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    animation: ${fade} 1s;
    margin-top: 10px;
  }
`;

export const FirstStep = styled.div`
  width: 275px;
`;

export const Triangle = styled.div`
  margin-left: 5px;
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 5px solid #444444;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
`;

const chevron = css`
  & path {
    stroke: #444444;
    transition: 0.5s;
  }
`;
export const WhiteHeaderChevron = css`
  ${chevron}
`;
export const ScrolledHeaderChevron = css`
  ${chevron}
`;

const mobileLanguageAndRubricWrapper = css`
  display: flex;
  align-items: center;

  & > button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #ffffff;
    align-items: flex-start;
  }
`;
export const MobileRubricWrapper = styled.div(mobileLanguageAndRubricWrapper);
export const MobileLanguageWrapper = styled.div(mobileLanguageAndRubricWrapper);

export const ArrowMobile = styled.span`
  position: absolute;
  left: 280px;

  & path {
    stroke: #444444;
  }
`;

export const Arrow = styled.span`
  transition: 0.5s;
  padding-left: 3px;
  position: relative;
  left: 3px;
  bottom: 2px;

  ${(props) =>
    handleHeaderClassChevron(
      props.$transparentHeader,
      props.$whiteHeader,
      WhiteHeaderChevron,
      ScrolledHeaderChevron
    )}
`;

export const PhoneSvg = styled(Phone)`
  margin-right: 4px;
  & path {
    transition: 0.5s;
    fill: ${(props) => props.theme.palette.main};
  }
`;
