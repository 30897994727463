import React, { useState, useEffect, useRef, useCallback } from "react";
import BrandIcon from "../../../BrandIcon";
import styled from "styled-components";
import {
  Wrapper,
  Arrow,
  LanguageDropDown,
  Language,
  LanguageButton,
  BurgerIcon,
  ButtonWrapper,
  MobileContainer,
  MobileWrapper
} from "../styles";
import { ReactComponent as Linkedin } from "../../../commons/svg/social/linkedin.svg";
import { ReactComponent as Chevron } from "../svgs/chevron.svg";

import { isPathWhiteHeader, locale } from "../helpers";

import useBlur from "../../../commons/hooks/useBlur";
import { getPathnameWithLocale } from "../../../helpers/getRouterBasename";

import MobileDropDown from "./MobileDropDown";
import useWindowDimensions from "../../../commons/hooks/useWindowDimensions";
import { translate as t, setLocale } from "../../../helpers/i18n";

const MobileHeader = () => {
  const [transparentHeader, setTransparentHeader] = useState(
    window.location.pathname === "/about"
  );
  const [whiteHeader, setWhiteHeader] = useState(
    isPathWhiteHeader(window.location.pathname)
  );
  const [language, setLanguage] = useState(false);

  const { width } = useWindowDimensions();

  const [mobileDropDown, setMobileDropDown] = useState(false);

  const [changeLanguage, setChangeLanguage] = useState(locale);

  const refButtonLanguage = useRef(null);
  const refLanguage = useRef(null);
  const mobileButtonRef = useRef(null);
  const mobileUserRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (window.location.pathname === "/about") {
      setTransparentHeader(window.scrollY <= 50);
      setWhiteHeader(false);
    }
    if (isPathWhiteHeader(window.location.pathname)) {
      setWhiteHeader(window.scrollY <= 50);
      setTransparentHeader(false);
    }
  }, []);

  const handleChange = (input) => {
    setLocale(input);
    window.location.pathname = getPathnameWithLocale(
      window.location.pathname,
      input
    );
  };

  useEffect(() => {
    if (width > 960) {
      setMobileDropDown(false);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, width]);
  useBlur((event) => {
    if (
      mobileDropDown &&
      mobileButtonRef.current &&
      mobileUserRef.current &&
      !mobileButtonRef.current.contains(event.target) &&
      !mobileUserRef.current.contains(event.target)
    ) {
      setMobileDropDown(false);
    }
  }, []);

  return (
    <Wrapper
      $transparentHeader={transparentHeader}
      $whiteHeader={whiteHeader}
      $mobileDropDown={mobileDropDown}
    >
      <MobileWrapper>
        <Left>
          <LogoC
            onClick={() => {
              const targetDiv = document.getElementById("top");
              targetDiv.scrollIntoView({
                behavior: "smooth",
                block: "start"
              });
              setMobileDropDown(false);
            }}
          >
            {<BrandIcon />}
          </LogoC>
          <LanguageDropDown>
            <LanguageButton
              ref={refButtonLanguage}
              onClick={() => setLanguage(true)}
              $transparentHeader={transparentHeader}
              $whiteHeader={whiteHeader}
              type="button"
            >
              {changeLanguage === "fr" ? "FR" : "EN"}
              <Arrow
                $transparentHeader={transparentHeader}
                $whiteHeader={whiteHeader}
              >
                <Chevron />
              </Arrow>
            </LanguageButton>
            {language && (
              <Language ref={refLanguage}>
                <button
                  type="button"
                  onClick={() => {
                    handleChange(changeLanguage === "fr" ? "en" : "fr");
                    setChangeLanguage(changeLanguage === "fr" ? "en" : "fr");
                  }}
                >
                  {t(changeLanguage === "fr" ? "English" : "Français")}
                </button>
              </Language>
            )}
          </LanguageDropDown>
        </Left>
        <MobileRightContainer>
          <Logos>
            <a
              href="https://www.linkedin.com/company/moving-on-transport/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </Logos>
          <ButtonWrapper
            ref={mobileButtonRef}
            className={ButtonWrapper}
            type="button"
            onClick={() => setMobileDropDown(!mobileDropDown)}
          >
            <BurgerIcon $cross={mobileDropDown} />
          </ButtonWrapper>
        </MobileRightContainer>
      </MobileWrapper>
      {mobileDropDown && (
        <MobileContainer ref={mobileUserRef}>
          <MobileDropDown
            mobileDropDown={mobileDropDown}
            setMobileDropDown={setMobileDropDown}
          />
        </MobileContainer>
      )}
    </Wrapper>
  );
};

const Left = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;
  & > *:last-child {
    margin-left: 20px;
  }
`;

const MobileRightContainer = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 15px;
  }
`;

const LogoC = styled.a`
  & > svg {
    height: 80px;
    width: 100px;
  }
`;

const Logos = styled.div`
  margin-top: 5px;
  & > * {
    margin-right: 20px;
  }
`;

export default MobileHeader;
