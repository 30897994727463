import React, { useEffect, useContext } from "react";

import { Context as MainContext } from "../../../commons/Components/MainContext";
import { translate as t } from "../../../helpers/i18n";

import Header from "./Header";
import Footer from "../../../Layout/Footer";
import Howitworks from "./Howitworks";
import CTA from "../Components/CTA";

// eslint-disable-next-line max-len
import { ReactComponent as SlopeColorWhite } from "../res/separators/slopeColorWhite.svg";
import BookMeeting from "./BookMeeting";
import { styled } from "styled-components";

const Landing = () => {
  const { setBodyPadding } = useContext(MainContext);

  useEffect(() => {
    setBodyPadding(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Header />
      <Howitworks />
      <BookMeeting />
      <SlopeColorWhite />
      <CTA
        title={t("Où nous trouver ?")}
        subtitle={t(
          "Gérer vos transports internationaux " +
            "n'aura jamais été si simple."
        )}
      />
      <Footer />
    </Wrapper>
  );
};

export default Landing;

const Wrapper = styled.div`
  max-width: 100vw;
  height: 100%;
  background-color: #ffffff;
  overflow: hidden;
`;
