import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";

import { ScrolledHeaderPhone, PhoneSvg, Text } from "../../styles";

import { Wrapper, About, LanguageButton } from "../../styles";

import { locale } from "../../helpers";

import { translate as t, setLocale } from "../../../../helpers/i18n";
import useBlur from "../../../../commons/hooks/useBlur";

import { ReactComponent as Chevron } from "../../svgs/chevron.svg";

import { getPathnameWithLocale } from "../../../../helpers/getRouterBasename";

const MobileDropDown = ({ mobileDropDown, setMobileDropDown }) => {
  const width = window.innerWidth;
  const [mobileLanguageButton, setMobileLanguageButton] = useState(false);
  const [mobileRubricButton, setMobileRubricButton] = useState(false);
  const [mobileAboutButton, setMobileAboutButton] = useState(false);
  const [mobileRessourcesButton, setMobileRessourcesButton] = useState(false);
  const [changeLanguage, setChangeLanguage] = useState(locale);

  const mobileButtonRef = useRef(null);
  const mobileUserRef = useRef(null);

  const handleChange = (input) => {
    setLocale(input);
    window.location.pathname = getPathnameWithLocale(
      window.location.pathname,
      input
    );
  };

  useEffect(() => {
    if (width > 960) {
      setMobileDropDown(false);
      setMobileLanguageButton(false);
      setMobileRubricButton(false);
    }
  }, [setMobileDropDown, width]);

  useBlur((event) => {
    if (
      mobileDropDown &&
      mobileButtonRef.current &&
      mobileUserRef.current &&
      !mobileButtonRef.current.contains(event.target) &&
      !mobileUserRef.current.contains(event.target)
    ) {
      setMobileDropDown(false);
      setMobileLanguageButton(false);
      setMobileRubricButton(false);
    }
  }, []);

  const scrollToSection = (id, yOffset) => {
    const element = document.getElementById(id);
    const y =
      element?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <>
      <Text
        onClick={() => {
          scrollToSection("skills", -100);
        }}
      >
        <Bold>{t("Compétences")}</Bold>
      </Text>
      <Text
        onClick={() => {
          scrollToSection("contact", -50);
        }}
      >
        <Bold>{t("Contactez-nous")}</Bold>
      </Text>
      <Text
        onClick={() => {
          scrollToSection("about", -100);
        }}
      >
        <Bold>{t("Où nous trouver?")}</Bold>
      </Text>

      <ScrolledHeaderPhone href="tel:+33252591498" rel="noopener noreferrer">
        <PhoneSvg />
        +33 2 52 59 14 40
      </ScrolledHeaderPhone>
    </>
  );
};

const Bold = styled.p`
  font-weight: 600;
  font-size: 16px;
`;

MobileDropDown.propTypes = {
  mobileDropDown: bool.isRequired,
  setMobileDropDown: func.isRequired
};

export default MobileDropDown;
