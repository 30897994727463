import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { Context } from './MainContext'

const StyledToast = styled.button`
  min-height: 40px;
  height: auto;
  width: auto;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  cursor: ${props => (props.closeable ? 'pointer' : 'unset')};
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: fixed;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  padding: 0 20px;
  border: none;
  z-index: 99;
  :focus {
    outline: none;
  }
  @media (max-width: 1020px) {
    bottom: 70px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 414px) {
    width: 100vw;
    border-radius: 0;
    display: flex;
    justify-content: center;
    bottom: 61px;
  }
  ${props => (props.displayToast
    ? css`
          opacity: 1;
          visibility: visible;
          transition: 0.3s;
        `
    : css`
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
        `)}
`

const IconWrapper = styled.div`
  margin-right: 10px;
  display: flex;
`

const TextWrapper = styled.div`
  color: inherit;
  font-size: 14px;
  & * {
    color: inherit;
    font-size: 14px;
  }
`

function Toast() {
  const { toastData, setToastData } = useContext(Context)

  const closeToast = () => {
    setToastData({
      display: false,
      persistent: false,
      closeable: false,
      duration: 0,
    })
  }

  if (toastData.display && !toastData.persistent) {
    const timeout = setTimeout(() => {
      closeToast()
      clearTimeout(timeout)
    }, toastData.duration)
  }

  return (
    <StyledToast
      displayToast={toastData.display}
      backgroundColor={toastData.backgroundColor}
      color={toastData.color}
      closeable={toastData.closeable}
      onClick={() => toastData.persistent && toastData.closeable && closeToast()
      }
      type="button"
    >
      {!!toastData.icon && <IconWrapper>{toastData.icon}</IconWrapper>}
      {!!toastData.text && <TextWrapper>{toastData.text}</TextWrapper>}
    </StyledToast>
  )
}

export default Toast
