import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { gsap } from "gsap";
import { translate as t } from "../../../../helpers/i18n";
import HeroImg from "../png/image.png";
import useWindowDimensions from "../../../../commons/hooks/useWindowDimensions";

const Header = () => {
  const { width } = useWindowDimensions();
  const franckImageRef = useRef(null);

  const handleFranckHover = () => {
    gsap.to(franckImageRef.current, { opacity: 1, duration: 0.5 });
  };

  const handleFranckLeave = () => {
    gsap.to(franckImageRef.current, { opacity: 0, duration: 0.5 });
  };

  const scrollToSection = (id, yOffset) => {
    const element = document.getElementById(id);
    const y =
      element?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <Background>
      <Wrapper id="top">
        <TopSection>
          <Container>
            <Title>
              <p>{t("Qui sommes-nous ?")}</p>
            </Title>
            <SubTitle>
              <p>
                {t(
                  "Fondée en 2023, l'entreprise Moving On transport  vous apporte des solutions de transport routier de marchandises à l'international. Toute l'équipe saura s'organiser pour répondre au mieux à vos attentes."
                )}
              </p>
            </SubTitle>
            <Buttons>
              <ThemedButton
                onClick={() => {
                  scrollToSection("contact", -50);
                }}
                type="button"
              >
                {t("Nous contacter")}
              </ThemedButton>
            </Buttons>
          </Container>
          <HeroImage src={HeroImg} alt="Tuck" />
        </TopSection>
      </Wrapper>
    </Background>
  );
};

export default Header;

const Background = styled.div`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1440px;

  @media (max-width: 1024px) {
    padding: 0;
    display: flex;
    justify-content: center;
  }
`;

const TopSection = styled.div`
  padding-top: 180px;
  height: 100vh;
  display: flex;
  position: relative;
  margin-bottom: -150px;
  @media (max-width: 1024px) {
    height: 90vh;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1600px) {
    margin-left: 40px;
  }

  @media (max-width: 1024px) {
    padding-top: 20px;
    margin: 0px;
  }
`;

const HeroImage = styled.img`
  position: absolute;
  transform: scale(1.1);
  width: 850px;
  right: -230px;
  top: 200px;
  height: auto;
  z-index: 0;

  @media (min-width: 1920px) {
    width: 800px;
  }

  @media (max-width: 1600px) {
    width: 600px;
    right: -40px;
  }

  @media (max-width: 1200px) {
    width: 400px;
    right: 0px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Title = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 55px;
  width: 60%;
  cursor: pointer;

  & > p {
    margin: 0;
    color: #444444;
  }

  @media (max-width: 1600px) {
    font-size: 42px;
    width: 52%;
  }

  @media (max-width: 1200px) {
    font-size: 38px;
    width: 58%;
  }

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 32px;
    margin: 0 auto;
    width: 80vw;
  }

  @media (max-width: 600px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 26px;
    width: 95vw;
  }
`;

const SubTitle = styled.div`
  margin-top: 15px;
  font-weight: 500;
  font-size: 24px;
  width: 48%;
  cursor: pointer;

  & > p {
    color: #76797f;
  }

  @media (max-width: 1600px) {
    font-size: 22px;
    width: 48%;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    width: 52%;
  }

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 16px;
    margin: 10px auto auto;
    width: 80vw;
  }

  @media (max-width: 600px) {
    text-align: left;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    width: 95vw;
  }
`;

const ButtonWrapper = css`
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  height: 60px;
  width: 100%;

  @media (max-width: 1200px) {
    width: 250px;
    padding: 10px;
  }

  @media (max-width: 480px) {
    width: 100%;
    font-size: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;

  width: 235px;
  margin: 55px 20px 15px 0;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a {
      margin: 0 20px 20px 0;
    }
  }

  @media (max-width: 600px) {
    & > a {
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    margin-top: 40px;

    & > a {
      margin: 0;
    }
  }
`;

const ThemedButton = styled.button`
  ${ButtonWrapper}
  background-color: ${(props) => `${props.theme.palette.main}4d`};
  color: ${(props) => props.theme.palette.main};
  :hover {
    background-color: ${(props) => `${props.theme.palette.main}66`};
  }
`;

const WhiteButton = styled.button`
  ${ButtonWrapper}
  background-color: #ffffff;
  color: ${(props) => props.theme.palette.main};
  :hover {
    background-color: #f8f8f8;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;
