import React from "react";
import styled from "styled-components";
import { ReactComponent as Linkedin } from "../../commons/svg/social/linkedin.svg";
// import { ReactComponent as Insta } from "../../commons/svg/social/instagram.svg";
// import { ReactComponent as Twitter } from "../../commons/svg/social/twitter.svg";
import { translate as t } from "../../helpers/i18n";
import Button from "../../commons/Components/Button";

const scrollToSection = (id, yOffset) => {
  const element = document.getElementById(id);
  const y =
    element?.getBoundingClientRect()?.top + window?.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

const Footer = () => (
  <>
    <Wrapper>
      <div>
        <Container>
          <Logos>
            <a
              href="https://www.linkedin.com/company/moving-on-transport/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin />
            </a>
          </Logos>
          <Legal>
            <a href="/legal_mentions.pdf" download>
              {t("MENTIONS LÉGALES")}
            </a>
            <br></br>
            <br></br>
            <a href="/CGVU.pdf" download>
              {"CGVU"}
            </a>
          </Legal>
          <Copyright>
            {`© ${new Date().getFullYear()} ${t(
              "Moving on transport. All rights reserved"
            )}`}
          </Copyright>
        </Container>
        <OthersLinks>
          <div>
            <Title>{t("NOTRE ADRESSE")}</Title>
            <div>
              <LinkWrapper>17 rue de la guillauderie</LinkWrapper>
              <LinkWrapper>44118 la Chevroliere</LinkWrapper>
            </div>
            <div>
              <LinkWrapper>
                SIRET : 981 954 274 00016<br></br>
              </LinkWrapper>
            </div>

            <div>
              <LinkWrapper>{t("SAS au capital de 8 000€")}</LinkWrapper>
            </div>
          </div>
        </OthersLinks>
        <OthersLinks>
          <div>
            <Title>{t("HORAIRES D'OUVERTURE")}</Title>
            <div>
              <LinkWrapper>{t("Du lundi au vendredi")}</LinkWrapper>
            </div>
            <div>
              <LinkWrapper>8h00 – 12h00 / 13h00 – 17h30</LinkWrapper>
            </div>
          </div>
        </OthersLinks>
        <CTA>
          <ButtonWrapper
            onClick={() => {
              scrollToSection("contact", -50);
            }}
            large
          >
            {t("Nous contacter")}
          </ButtonWrapper>
        </CTA>
      </div>
    </Wrapper>
  </>
);

export default Footer;

const CTA = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 !important;
  }
`;

const Legal = styled.div`
  margin-top: 20px;
  & > a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
`;

const Wrapper = styled.div`
  display: flex;
  background-color: #19222c;
  min-height: 275px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  & > div {
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 815px) {
    flex-direction: column-reverse;
    & > div {
      padding-bottom: 30px;
    }
  }
`;

const Container = styled.div`
  @media (max-width: 815px) {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 550px) {
    margin: 0 0 20px 0;
  }
`;

const Copyright = styled.p`
  margin-top: 20px;
  font-size: 12px;
  color: #ffffff;
`;

const Logos = styled.div`
  margin-top: 30px;
  & > a:not(:last-child) {
    margin-right: 20px;
  }
`;

const OthersLinks = styled.div`
  display: flex;
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > div:not(:last-child) {
    margin-right: 100px;
  }
  & > div > *:not(:last-child) {
    margin-bottom: 10px;
  }
  @media (max-width: 815px) {
    & > div {
      margin: 20px 0 20px 20px;
    }
  }
  @media (max-width: 550px) {
    width: 100%;
    flex-direction: column;
  }
`;

const LinkWrapper = styled.p`
  display: flex;
  font-size: 16px;
  text-decoration: none;
  color: #afafaf;
  background: none;
  border: none;
  cursor: pointer;
`;

const Title = styled.p`
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
`;

const ButtonWrapper = styled(Button)`
  height: 55px;
  margin: 50px 0 100px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
`;
