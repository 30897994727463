import React, { createContext, useReducer, useMemo } from 'react'
import { oneOfType, arrayOf, node } from 'prop-types'

import Toast from './Toast'

export const Context = createContext()

const reducer = (current, { name, value }) => {
  switch (name) {
    case 'toastData':
      return { ...current, toastData: { ...current.toastData, ...value } }
    default:
      return { ...current, [name]: value }
  }
}

const init = () => ({
  hostname: window.location.hostname,
  displayHeader: true,
  bodyPadding: true,
  toastData: {
    display: false,
    persistent: false,
    closeable: false,
    duration: 0,
    backgroundColor: '#00a6a6',
    color: 'white',
    icon: null,
    text: null,
  },
  alerts: [],
})

const MainContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null, init)

  const data = useMemo(
    () => ({
      ...state,
      setDisplayHeader: (value) => {
        dispatch({ name: 'displayHeader', value })
        dispatch({ name: 'bodyPadding', value })
      },
      setBodyPadding: value => dispatch({ name: 'bodyPadding', value }),
      setToastData: value => dispatch({ name: 'toastData', value }),
      getAlerts: () => state.alerts,
      setAlerts: value => dispatch({ name: 'alerts', value }),
    }),
    [state],
  )

  return (
    <Context.Provider value={data}>
      {children}
      <Toast />
    </Context.Provider>
  )
}

MainContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
}

export default MainContextProvider
