import { getLocale } from "../../helpers/i18n";
import scrollToTop from "../../helpers/scrollToTop";
import { getPathnameWithoutLocale } from "../../helpers/getRouterBasename";

export const locale = getLocale();

export const isPathTransparentHeader = (pathname) => {
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname);
  const pathsTransparentHeader = ["/about"];

  if (pathsTransparentHeader.includes(pathnameWithoutLocale)) return true;
  if (!pathnameWithoutLocale) return true;

  return false;
};

export const isPathWhiteHeader = (pathname) => {
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname);
  const pathsWhiteHeader = [
    "/",
    "/confidentiality",
    "/terms-and-conditions",
    "/price-conditions",
    "/cookie-policy",
    "/legal-mentions",
    "/pme",
    "/startup",
    "/large-company",
    "/quality-control",
    "/forwarder",
    "/companies"
  ];

  if (pathsWhiteHeader.includes(pathnameWithoutLocale)) return true;
  if (pathnameWithoutLocale.includes("/company")) return true;
  if (pathnameWithoutLocale.includes("/project")) return true;

  return false;
};

export const handleHeaderClass = (
  transparentHeader,
  whiteHeader,
  ScrolledHeader,
  TransparentHeader,
  WhiteHeader
) => {
  if (transparentHeader) return TransparentHeader;
  if (whiteHeader) return WhiteHeader;
  return ScrolledHeader;
};

export const handleHeaderClassChevron = (
  transparentHeader,
  whiteHeader,
  WhiteHeaderChevron,
  ScrolledHeaderChevron
) => {
  if (transparentHeader) return "";
  if (whiteHeader) return WhiteHeaderChevron;
  return ScrolledHeaderChevron;
};

export const handleHeaderPhoneClass = (
  transparentHeader,
  whiteHeader,
  TransparentHeaderPhone,
  WhiteHeaderPhone,
  ScrolledHeaderPhone
) => {
  if (transparentHeader) return TransparentHeaderPhone;
  if (whiteHeader) return WhiteHeaderPhone;
  return ScrolledHeaderPhone;
};

export const topPageRedirectionMobileRubric = (
  setMobileRubricButton,
  mobileRubricButton
) => {
  scrollToTop();
  setMobileRubricButton(!mobileRubricButton);
};

export const topPageRedirectionMobileForwarder = (
  setMobileDropDown,
  mobileDropDown
) => {
  scrollToTop();
  setMobileDropDown(!mobileDropDown);
};

export const topPageRedirectionMobileHelp = (
  setMobileRessourcesButton,
  mobileRessourcesButton
) => {
  scrollToTop();
  setMobileRessourcesButton(!mobileRessourcesButton);
};
