import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'

const Title = ({ className, ...props }) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <Text {...props} className={className} />
)

Title.defaultProps = { className: '' }
Title.propTypes = { className: string }

export default Title

const Text = styled.h2`
  text-align: center;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.5em;
  user-select: none;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 30px;
  }
`
